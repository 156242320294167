import axios from "api/interceptor";
import { createSlice } from "@reduxjs/toolkit";
import { errorHandler } from "api/errorHandler";

/**
 * Setters & Getters
 */
const setToken = (token) => {
  localStorage.setItem("token", token);
};

const getToken = () => {
  return localStorage.getItem("token") || null;
};

/**
 * Initial state
 */
const initialState = {
  token: getToken(),
  user: null,
  loggedIn: false,
  loader: true,
};

/**
 * Slice
 */
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      const { jwt, user } = action.payload;

      setToken(jwt);

      state.token = jwt;
      state.user = user;
      state.loggedIn = true;
    },

    refresh: (state, action) => {
      const user = action.payload;

      state.user = user;
      state.loggedIn = true;
    },

    logout: (state) => {
      localStorage.clear();

      state = {
        token: null,
        user: null,
        loggedIn: false,
      };
    },
    startUserLoader: (state) => {
      state.loader = true;
    },
    stopUserLoader: (state) => {
      state.loader = false;
    },
  },
});

/**
 * Reducer
 */
export default userSlice.reducer;

/**
 * Actions
 */
export const { login, refresh, logout, startUserLoader, stopUserLoader } =
  userSlice.actions;

/**
 * Selectors
 */
export const selectUser = (state) => state.user.user;
export const selectToken = (state) => state.user.token;
export const selectLoggedIn = (state) => state.user.loggedIn;
export const selectUserLoader = (state) => state.user.loader;

/**
 * Thunks
 */
export const loginRequest = (data, setSubmitting) => (dispatch) => {
  axios({
    method: "post",
    url: "/auth/local",
    data: data,
  })
    .then((res) => {
      setSubmitting && setSubmitting(false);
      dispatch(login(res.data));
    })
    .catch((err) => {
      setSubmitting && setSubmitting(false);
      errorHandler(err);
    });
};

export const signupRequest = (data, setSubmitting) => (dispatch) => {
  axios({
    method: "post",
    url: "/auth/local/register",
    data: data,
  })
    .then((res) => {
      setSubmitting && setSubmitting(false);
      dispatch(login(res.data));
    })
    .catch((err) => {
      setSubmitting && setSubmitting(false);
      errorHandler(err);
    });
};

export const refreshUser = () => (dispatch, getState) => {
  const token = selectToken(getState());
  axios({
    method: "get",
    url: `/users/me`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      dispatch(refresh(res.data));
      dispatch(stopUserLoader(getState()));
    })
    .catch((err) => {
      errorHandler(err);
      dispatch(logout());
      dispatch(stopUserLoader(getState()));
    });
};