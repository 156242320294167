import { useEffect, useState } from "react";
import axios from "api/interceptor";

import Loader from "components/global/loader/Loader";
import styles from "./LandsSearch.module.css";
import { Link, useLocation } from "react-router-dom";
import { errorHandler } from "api/errorHandler";
import LandsFilter from "../../../components/filters/LandsFilter";
import LandCard from "components/cards/landcard/LandCard";
const qs = require("query-string");

export default function LandSearch() {
  const [lands, setLands] = useState([]);
  const [landLoader, setLandLoader] = useState(true);

  let { search } = useLocation();
  const { landType } = qs.parse(search);

  let queryURL;

  if (landType) {
    queryURL = `/lands?landType=${landType}&_sort=createdAt:DESC`;
  } else queryURL = `/lands?_sort=createdAt:DESC`;

  useEffect(() => {
    axios({
      method: "get",
      url: queryURL,
    })
      .then((res) => {
        setLandLoader(false);
        setLands(res.data);
      })
      .catch((err) => {
        setLandLoader(false);
        errorHandler(err);
      });
    //eslint-disable-next-line
  }, []);

  return (
    <div className={styles.search_container}>
      {landLoader ? (
        <Loader />
      ) : (
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-h-full tw-bg-gray-200">
          <div>
            <LandsFilter landType={landType || ""} setLands={setLands} setLandLoader={setLandLoader} />
          </div>

          {lands.length > 0 ? (
            <div
              className={`tw-flex tw-flex-wrap tw-justify-start ${styles.landtiles}`}
            >
              {lands.map((land, i) => (
                <LandCard key={i} land={land} />
              ))}
            </div>
          ) : (
            <div className="tw-m-auto">
              <div className="tw-text-left tw-text-2xl tw-pb-2">
                No Lands Found
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
