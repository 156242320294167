import React, { useState, useRef, useEffect } from "react";

import Microsoft from "../../images/microsoft.png";
import Tcs from "../../images/tcs.png";
import Infosys from "../../images/infosys.png";
import Google from "../../images/google.png";
import Apollo from "../../images/apollo.png";
import Pranathi from "../../images/pranathi.png";
import Builder from "../../images/builder.png";
import Agent from "../../images/agent.png";
import Customer from "../../images/customer.png";
import Customer1 from "../../images/Prasanna.jpg";
import Customer2 from "../../images/Ravi.jpg";
import Customer3 from "../../images/Susheel.jpg";
import "../../index.css";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

export default function Home() {
  const history = useHistory();
  const title = "Trusted Land Discovery Partner";
  const button_class =
    "tw-font-semibold Button-module--button--3-a61 Button-module--pill--J7ezZ Button-module--default--2nGLz Button-module--rounded--3dVkS  tw-uppercase fontsize ";
  const Tile = ({ title }) => (
    <>
      <Link
        to="/get_callback"
        className="tw-block  tw-relative  tw-overflow-hidden  tw-rounded-xl tw-bg-green-100   sm:tw-h-44  _-module--serviceSelectorBlockContainer--1nHGb"
      >
        <div className="_-module--serviceSelectorOverlay--2N1P4"></div>
        <div className="tw-grid tw-grid-cols-4 sm:tw-grid-cols-1 tw-items-center  tw-relative  tw-h-full    tw-pl-4 tw-pr-5 tw-py-4 sm:tw-px-5 sm:tw-py-6  tw-overflow-hidden tw-font-extrabold">
          {/* <img
            className="tw-w-12 tw-h-12 sm:tw-row-start-2"
            alt="Blogs &amp; Articles"
            width="48"
            height="48"
          /> */}
          <h3
            style={{ fontWeight: "bold" }}
            className="paragraph-base  tw-flex tw-items-center sm:tw-items-start  tw-col-span-2 sm:tw-row-start-1  sm:tw-mb-4 _-module--cardTitle--PMbUo "
          >
            {title}
          </h3>
        </div>
      </Link>
    </>
  );

  const PropertyTiles = ({ image, title, link }) => (
    <>
      <Link to={link}>
        <div className="Property-Tile">
          <img src={image} alt="Property-tile" />

          <div className="property-tile-content">
            <h1>{title}</h1>

            <button
              type="button"
              className="property-button"
              style={{ fontWeight: "600" }}
            >
              Explore
            </button>
          </div>
        </div>
      </Link>
    </>
  );
  const NumberDiv = ({ value, title, link }) => (
    <>
      <div className="fbox">
        {/* <img src={link} alt={title} className="stats_img" /> */}
        <p id="live-site" className="f-digit">
          {value}
        </p>
        <p id="live-site-c" className="f-text">
          {title}
        </p>
      </div>
    </>
  );
  const ServiceCard = ({ title, text1, img }) => (
    <>
      <div className="ServiceCard">
        <p className="Service-Card-title">{title}</p>
        <br />

        {/* <h1 className="Service-desc">
          Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
        </h1> */}
        <ul>
          {text1.map((pointer) => (
            <>
              <li>{pointer[0]}</li>
              <p className="point-desc">{pointer[1]}</p>
            </>
          ))}
        </ul>
      </div>
    </>
  );

  const Reviews = ({ images, title, text, name }) => (
    <>
      <div className="review-container">
        <div className="review-header">
          <div
            style={{
              overflow: "hidden",
              borderRadius: "50px",
              width: "80px",
              marginRight: "10px",
            }}
          >
            <img src={images} alt="profile" className="review-img" />
          </div>

          <p className="p1">
            <span style={{ fontSize: "1.2em", fontWeight: "500" }}>{name}</span>{" "}
            <br /> {title}
          </p>
        </div>
        <p className="p3">{text}</p>
      </div>
    </>
  );

  const [clicked, setClicked] = useState("Featured");
  return (
    <div>
      <section className="max-desktop-width  tw-grid tw-gap-8 lg:tw-grid-cols-12   tw-mx-auto tw-py-12 tw-px-5 sm:tw-px-10 lg:tw-px-5   tw-text-gray-800 _-module--container--3q17W">
        <div className="tw-col-start-1 lg:tw-col-end-8 lg:tw-mt-10 lg:tw-mb-24">
          <h1 className="tw-h1  tw-font-semibold tw-text-gray-800  tw-mb-6">
            {title}
          </h1>
          <h2 className="paragraph-lg tw-mb-8  tw-text-gray-800">
            Lands360 is tech driven platform which does over 30 validations for
            each land to bring you genuine properties. Our vast list of 30+
            services will make land dealings smooth, secure, and simple.
          </h2>
          <button
            onClick={() => history.push("/lands")}
            className="tw-font-semibold Button-module--button--3-a61 Button-module--primary--Zwv2c Button-module--large--2Qg2d Button-module--rounded--3dVkS  tw-flex tw-justify-center tw-items-center tw-h4  tw-w-full sm:tw-w-auto  tw-mb-6 button-flex"
          >
            <span>Buy Lands</span>
          </button>
          <button
            onClick={() => history.push("/dashboard/listing")}
            className="tw-font-semibold Button-module--button--3-a61 Button-module--primary--Zwv2c Button-module--large--2Qg2d Button-module--rounded--3dVkS  tw-flex tw-justify-center tw-items-center tw-h4  tw-w-full sm:tw-w-auto  tw-mb-6 button-flex"
          >
            <span>Sell Lands</span>
          </button>
        </div>
        <div className="lg:col-start-8 lg:col-end-13 lg:justify-self-end">
          <img
            className="mx-auto _-module--heroImg--1F3_1"
            src="https://img.freepik.com/free-vector/city-buildings-cute-landscape-flat-illustration_213110-152.jpg?size=626&ext=jpg"
            alt="Enterprise Dashboard"
            width="520"
            height="466"
          />
        </div>
      </section>
      <section className="max-desktop-width  tw-mx-auto tw-pt-6 tw-pb-8">
        <h1 className="paragraph-xl  tw-text-center  tw-mb-4  tw-font-semibold  tw-text-gray-300">
          Trusted by Builders & Customers working at Top Companies
        </h1>
        <div className="tw-grid tw-grid-cols-2 tw-gap-4 sm:tw-grid-cols-3 lg:tw-grid-cols-6 lg:tw-gap-8 tw-justify-items-center ">
          <img src={Microsoft} alt="Directi" width="128" height="56" />
          <img src={Tcs} alt="Directi" width="128" height="56" />
          <img src={Infosys} alt="Directi" width="128" height="56" />
          {/* <img src={Apple} alt="Directi" width="128" height="56" /> */}
          <img src={Google} alt="Directi" width="128" height="56" />
          <img src={Apollo} alt="Directi" width="128" height="56" />
          <img src={Pranathi} alt="Directi" width="128" height="56" />
        </div>
      </section>
      <div className="Property-Container">
        <h1 className="Service-heading">
          Solving your pain points in most efficient way
        </h1>
        <div className="Service-Card-holder">
          <ServiceCard
            title="Builder"
            key={"1"}
            img={Builder}
            text1={[
              [
                "Reduce Site Visits",
                "Our Video tour of Site and Surroundings helps avoid initial site visits.",
              ],
              [
                "Avoid Buying at High Price",
                "Get to know the recent transactions for better price negotiation.",
              ],
              [
                "Eliminate Useless Lands",
                "We present lands with great business opportunity with detailed feasibility analysis.",
              ],
            ]}
          />
          <ServiceCard
            title="Agent"
            key={"2"}
            img={Agent}
            text1={[
              [
                "Reduce Settlement Issues",
                "With our Secure Settlement feature protect your commission.",
              ],
              [
                "Get rid of Slow Sales",
                "With our big network of customers and builders, you can close deals faster.",
              ],
              [
                "Reduce Fake enquires",
                "Our team validates buyer authenticity to give you genuine enquiries.",
              ],
            ]}
          />
          <ServiceCard
            title="Customer"
            key={"3"}
            img={Customer}
            text1={[
              [
                "Avoid Fear of Legal Issues",
                "Our expert Legal team will provide Legal opinion.",
              ],
              [
                "Avoid Buying at High Price",
                "Get to know the recent transactions for better price negotiation.",
              ],
              [
                "Reduce Time to Sell",
                "With our big network of customers and trusted agents, you can sell lands faster.",
              ],
            ]}
          />
        </div>
      </div>

      <div style={{ padding: "60px 0" }}>
        <h1 className="Service-heading">Find Your Dream Property</h1>
        <div className="Property-Tiles-Container">
          <PropertyTiles
            title="Residential Plots"
            link={`/lands?landType=Residential`}
            image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdgeGP0d4U8b4DyX0aDdVKzK5rb2onyNroPA&usqp=CAU"
          />
          <PropertyTiles
            title="Agricultural Lands"
            link={"/lands?landType=Agriculture"}
            image="https://eco-business.imgix.net/ebmedia/fileuploads/soybean-field-farming-field-agriculture.jpg?fit=crop&h=960&ixlib=django-1.2.0&w=1440"
          />
          <PropertyTiles
            title="Commercial Land"
            link={"/lands?landType=Commercial"}
            image="https://media.istockphoto.com/photos/office-and-residential-skyscrapers-against-bright-sun-and-clear-blue-picture-id1148496304?k=6&m=1148496304&s=612x612&w=0&h=FQ4hiCV-Z9B4yKdsMv7ScgTu3SfIr9KaW9fzfUutfng="
          />
        </div>
      </div>

      <div
        style={{
          backgroundColor: "rgba(214, 214, 214, 0.11)",
          marginTop: "50px",
        }}
      >
        <section className="max-desktop-width  tw-mx-auto tw-py-12 lg:tw-py-22 tw-px-5">
          <h2 className="tw-h2  tw-font-semibold tw-text-gray-800  tw-text-center  tw-mb-8">
            Services that Make Land Dealing Efficient & Smooth
          </h2>
          <nav className=" tw-overflow-auto  tw-text-center  tw-mb-8 lg:tw-mb-12 tw-space-x-2 lg:tw-space-x-5  _-module--scrollSnap--3GMmb">
            <button
              className={
                clicked === "Featured"
                  ? `${button_class} active`
                  : `${button_class}`
              }
              onClick={() => setClicked("Featured")}
            >
              <span>Featured</span>
            </button>
            <button
              className={
                clicked === "Land Search"
                  ? `${button_class} active`
                  : `${button_class}`
              }
              onClick={(e) => setClicked("Land Search")}
            >
              <span>Land Search</span>
            </button>
            <button
              className={
                clicked === "Transaction"
                  ? `${button_class} active`
                  : `${button_class}`
              }
              onClick={() => setClicked("Transaction")}
            >
              <span>Transaction</span>
            </button>
            <button
              className={
                clicked === "Development"
                  ? `${button_class} active`
                  : `${button_class}`
              }
              onClick={() => setClicked("Development")}
            >
              <span>Development</span>
            </button>
            <button
              className={
                clicked === "Maintainance"
                  ? `${button_class} active`
                  : `${button_class}`
              }
              onClick={() => setClicked("Maintainance")}
            >
              <span>Maintainance</span>
            </button>

            <button
              className={
                clicked === "Market Analysis"
                  ? `${button_class} active`
                  : `${button_class}`
              }
              onClick={() => setClicked("Market Analysis")}
            >
              <span>Market Analysis</span>
            </button>
          </nav>
          <div className="tw-grid tw-grid-cols-1 tw-gap-2 sm:tw-grid-cols-3 lg:tw-grid-cols-3 sm:tw-gap-4  tw-mb-8  tw-max-w-3xl  tw-mx-auto">
            {clicked === "Land Search" && (
              <>
                <Tile title="Premium Land Search Assistance" />
                <Tile title="Premium Seller Assist" />
                <Tile title="Premium Buyer Assist" />
                <Tile title="Basic Legal Checks" />
                <Tile title="Advanced Legal Opinion" />
              </>
            )}
            {clicked === "Transaction" && (
              <>
                <Tile title="Secure Settlements" />
                <Tile title="Escrow services" />
                <Tile title="Collect Land Docs from Govt Offices" />
                <Tile title="Schedule Meetings with both parties" />
                <Tile title="Land Survey" />
              </>
            )}
            {clicked === "Development" && (
              <>
                <Tile title="Layout" />
                <Tile title="Fencing" />
                <Tile title="Roads" />
                <Tile title="Bores" />
                <Tile title="Plantation" />
              </>
            )}
            {clicked === "Maintainance" && (
              <>
                <Tile title="Property management" />
                <Tile title="Lease Management" />
              </>
            )}
            {clicked === "Featured" && (
              <>
                <Tile title="Revenue Generation for Lands" />
                <Tile title="Premium Listings" />
                <Tile title="Video Tour of Land" />
                <Tile title="Drone Shots" />
                <Tile title="Feasibility Analysis" />
                <Tile title="Top 5 Requirements" />
                <Tile title="Top 5 For Sale" />
                <Tile title="Paid Banner Ad" />
                <Tile title="Expert Consultation" />
                <Tile title="Digital Marketing" />
              </>
            )}
            {clicked === "Market Analysis" && (
              <>
                <Tile title="Recent Transactions Data" />
                <Tile title="Project Feasibility Analysis" />
                <Tile title="Expression of Interest for Projects" />
              </>
            )}
          </div>
          {/* <p className="paragraph-lg  tw-text-center  tw-mb-6 tw-mx-auto  tw-max-w-screen-md">
          Whether you are running a startup or an enterprise, our services are
          widely distributed for every requirement. All of the content. None of
          the hassle.
        </p> */}
          <form action="/get_callback">
            <button
              className="tw-font-semibold Button-module--button--3-a61 Button-module--primary--Zwv2c Button-module--default--2nGLz Button-module--rounded--3dVkS  tw-flex tw-justify-center tw-items-center tw-h5  tw-block  tw-mx-auto"
              href="/form"
            >
              <span>Get a Call back</span>
              <div className="tw-ml-2">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    fillOpacity="0.01"
                  ></rect>
                  <path
                    d="M2.5 12.5H22.5"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                  ></path>
                  <path
                    d="M15.5 5.5L22.5 12.5L15.5 19.5"
                    stroke="currentColor"
                    strokeMiterlimit="10"
                    strokeLinecap="square"
                  ></path>
                </svg>
              </div>
            </button>
          </form>
        </section>
      </div>
      <h1 className="Service-heading">Defining Trusted Lands Market Place</h1>
      <div className="stats">
        <NumberDiv value={"400+"} title={"Buyers"} />
        <NumberDiv value={"100+"} title={"Sellers"} />
        <NumberDiv value={"100+"} title={"Deals"} />
        <NumberDiv value={"900+"} title={"Enquires"} />
      </div>
      <div
        style={{
          backgroundColor: "rgba(214, 214, 214, 0.11)",
          marginTop: "50px",
          padding: "50px 0",
        }}
      >
        <h1 className="Service-heading">Our Customers Love Us</h1>
        <div className="reviews-main" style={{ marginTop: "50px" }}>
          <Reviews
            title={"MD, KK Contructions"}
            name={"Mr. Prasanna"}
            images={Customer1}
            text={
              "Lands360 understood my requirement for apartment project very well and showed lands which exactly matched my requirements. I was able to quickly select a best one and started my project."
            }
          />
          <Reviews
            title={"Software Engineer, BoA"}
            name={"Mr. Susheel"}
            images={Customer3}
            text={
              "Very Professional and best in suggesting lands with future growth potential. Land discovery became very smooth with Lands360 services. Really appreciate Lands360 team."
            }
          />
          <Reviews
            title={"Agent"}
            name={"Mr. Ravi"}
            images={Customer2}
            text={
              "I am thankful for Lands360 platform which is solving major pain points of Agents interms of eliminating fake enquiries and securing our settlements. Now I can focus on solving my customer requirements better."
            }
          />
        </div>
      </div>
    </div>
  );
}
