import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import mainEn from "lang/main/main.en";
import mainHi from "lang/main/main.hi";
import mainTe from "lang/main/main.te";

import authEn from "lang/auth/auth.en";
import authHi from "lang/auth/auth.hi";
import authTe from "lang/auth/auth.te";

import dashboardEn from "lang/dashboard/dashboard.en";
import dashboardHi from "lang/dashboard/dashboard.hi";
import dashboardTe from "lang/dashboard/dashboard.te";

const resources = {
  en: {
    translation: {
      main: mainEn,
      auth: authEn,
      dashboard: dashboardEn,
    },
  },
  hi: {
    translation: {
      main: mainHi,
      auth: authHi,
      dashboard: dashboardHi,
    },
  },
  te: {
    translation: {
      main: mainTe,
      auth: authTe,
      dashboard: dashboardTe,
    },
  },
};

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  resources,
});

export default i18n;
