import React from "react";
import Facebook from "images/facebook.svg";

export default function Footer() {
  return (
    <div>
      <footer className="tw-bg-black">
        <section className="max-desktop-width  tw-grid tw-gap-x-8 sm:tw-grid-cols-2 lg:tw-grid-cols-4  tw-px-5 tw-pt-6 tw-pb-8 sm:tw-px-10 lg:tw-px-5 tw-mx-auto">
          <div className="tw-mb-6 lg:tw-mb-0">
            <h3 className="paragraph-xxs tw-font-semibold tw-uppercase  |  tw-mb-4 tw-text-white">
              Company
            </h3>
            <ul>
              <li className="tw-mb-2">
                <a
                  className="paragraph-xs  |  tw-inline-block tw-text-gray-400"
                  href="/about"
                >
                  About Us
                </a>
              </li>
            </ul>
          </div>
          <div className="tw-mb-6 lg:tw-mb-0">
            <h3 className="paragraph-xxs tw-font-semibold tw-uppercase  |  tw-mb-4 tw-text-white">
              Address
            </h3>
            <address className="paragraph-xs tw-not-italic  |  tw-inline-block tw-text-gray-400">
              Vaishnavi's Cynosure, 2nd Floor,
              <br /> KeyOnPlus, 2-48/5/6,
              <br /> Gachibowli Rd, Opp RTTC,
              <br /> Hyderabad - 500032
            </address>
          </div>
          <div className="tw-mb-6 lg:tw-mb-0">
            <h3 className="paragraph-xxs tw-font-semibold tw-uppercase  |  tw-mb-4 tw-text-white">
              Contact
            </h3>
            <div className="paragraph-xs  tw-inline-block tw-text-white">
              For Any Queries
              <br />
              please connect with us at&nbsp;
              <br />
              <p>
                Mobile:{" "}
                <span className="tw-text-yellow-200">
                  +91 8309344970
                </span>
              </p>
              <a
                className="lg:tw-block tw-text-yellow-200"
                href="mailto:support@lands460.in"
              >
                support@lands360.in
              </a>
            </div>
          </div>
          <div className="tw-mb-6 lg:tw-mb-0">
            <h3 className="paragraph-xxs tw-font-semibold tw-uppercase  |  tw-mb-4 tw-text-white">
              Social
            </h3>
            <div className="tw-flex  tw-space-x-6">
              <a title="Facebook" target="_blank" rel="noreferrer">
                <img
                  src={Facebook}
                  alt="twitter"
                  loading="lazy"
                  width="24"
                  height="24"
                />
              </a>
            </div>
          </div>
        </section>
        <hr className="max-desktop-width  |  tw-mx-5 lg:tw-mx-auto tw-border-gray-200" />
        <section className="max-desktop-width  lg:tw-flex  tw-px-5 tw-py-6 sm:tw-px-10 lg:tw-px-5 tw-mx-auto lg:tw-space-x-8">
          <p className="paragraph-xs  tw-inline-block  tw-text-gray-300  tw-mb-2 ">
            © 2021, All Rights Reserved.
          </p>
          <li className="paragraph-xs  tw-mb-2  tw-list-none">
            <a className="  tw-inline-block  tw-text-gray-300" href="/terms">
              Terms of Service
            </a>
          </li>
          <li className="paragraph-xs  tw-mb-2  tw-list-none">
            <a className="  tw-inline-block  tw-text-gray-300" href="/privacy">
              Privacy Policy
            </a>
          </li>
          <li className="paragraph-xs  tw-mb-2  tw-list-none">
            <a
              className="  tw-inline-block  tw-text-gray-300"
              href="/sitemap.xml"
            >
              Sitemap
            </a>
          </li>
        </section>
      </footer>
    </div>
  );
}
