import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { selectLoggedIn, selectUserLoader } from "app/slices/userSlice";

import { useSelector } from "react-redux";
import AuthGuard from "components/guards/AuthGuard";

import Home from "pages/home/Home";
import Login from "pages/login/Login";
import Form from "pages/form/Form";
import Signup from "pages/signup/Signup";
import Navbar from "components/global/navbar/Navbar";
import Dashboard from "pages/dashboard/Dashboard";
import About from "pages/about/About";
import Loader from "components/global/loader/Loader";
import Listing from "pages/dashboard/listing/Listing";
import Requirements from "pages/dashboard/requirements/Requirements";
import Land from "pages/lands/Land";
import LandsSearch from "pages/lands/search/LandsSearch";
import Footer from "components/global/footer/Footer";
import Header from "components/global/header/Header";
import ScrollToTop from "components/global/ScrollToTop";
import NavbarBoot from "components/global/navbarBoot/NavbarBoot";

export default function Router() {
  const loggedIn = useSelector(selectLoggedIn);
  const isLoading = useSelector(selectUserLoader);

  return (
    <BrowserRouter>
      <ScrollToTop />

      {/* <Navbar /> */}
      <NavbarBoot />
      {/* <Header /> */}
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/login">
          {isLoading ? (
            <Loader />
          ) : (
            <AuthGuard auth={loggedIn} to="/dashboard">
              <Login />
            </AuthGuard>
          )}
        </Route>
        <Route exact path="/signup">
          {isLoading ? (
            <Loader />
          ) : (
            <AuthGuard auth={loggedIn} to="/dashboard">
              <Signup />
            </AuthGuard>
          )}
        </Route>
        <Route exact path="/dashboard">
          {isLoading ? (
            <Loader />
          ) : (
            <AuthGuard auth={!loggedIn} to="/login">
              <Dashboard />
            </AuthGuard>
          )}
        </Route>
        <Route exact path="/dashboard/listing">
          {isLoading ? (
            <Loader />
          ) : (
            <AuthGuard auth={!loggedIn} to="/login">
              <Listing />
            </AuthGuard>
          )}
        </Route>
        <Route exact path="/dashboard/requirements">
          {isLoading ? (
            <Loader />
          ) : (
            <AuthGuard auth={!loggedIn} to="/login">
              <Requirements />
            </AuthGuard>
          )}
        </Route>
        <Route exact path="/lands">
          <LandsSearch />
        </Route>

        <Route exact path="/lands/:name">
          <Land />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/get_callback">
          <Form />
        </Route>
        <Redirect from="*" to="/" />
      </Switch>

      <Footer />
    </BrowserRouter>
  );
}
