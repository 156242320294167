import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styles from "./Listing.module.css";
import { useState } from "react";
import { Steps } from "antd";
import { listProperty, updateListedProperty } from "app/slices/dashboardSlice";
import Map from "components/maps/map/Map";
import { useHistory } from "react-router";

const { Step } = Steps;

const listingTypeMap = {
  Sale: "price",
  Lease: "rent",
  Development: "advance",
};

const PropertyDetails = (props) => {
  const dispatch = useDispatch();
  const {
    t,
    initialValues,
    validationSchema,
    validate,
    setPropertyDetails,
    setLandSubtype,
    setListingType,
  } = props;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        setLandSubtype(values.landType);
        setListingType(values.listingType);

        if (listingTypeMap[values.listingType] === "price") {
          delete values.advance;
          delete values.rent;
          delete values.developerShare;
        }
        if (listingTypeMap[values.listingType] === "rent") {
          delete values.advance;
          delete values.price;
          delete values.developerShare;
        }
        if (listingTypeMap[values.listingType] === "advance") {
          delete values.price;
          delete values.rent;
        }

        dispatch(listProperty(values, setSubmitting, setPropertyDetails));
      }}
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Form className={styles.form_container}>
          <label htmlFor="landType" className={styles.form_label}>
            {t("dashboard.landType")}
          </label>
          <div className={styles.form_field}>
            <Field name="landType" as="select" className="form-select">
              <option value="">Select Listing Type</option>
              <option value="Agriculture">{t("dashboard.agriculture")}</option>
              <option value="Residential">{t("dashboard.residential")}</option>
              <option value="Commercial">{t("dashboard.commercial")}</option>
            </Field>
            <p className={styles.form_valid_error}>
              <ErrorMessage name="landType" />
            </p>
          </div>
          <label htmlFor="listingType" className={styles.form_label}>
            {t("dashboard.listingType")}
          </label>
          <div className={styles.form_field}>
            <Field
              name="listingType"
              as="select"
              className="form-select"
              onChange={(e) => {
                if (e.target.value) {
                  setFieldValue("listingType", e.target.value);
                  setFieldValue("price", "");
                  setFieldValue("rent", "");
                  setFieldValue("advance", "");
                }
              }}
            >
              <option value="">Select Listing Type</option>
              <option value="Sale">{t("dashboard.sale")}</option>
              <option value="Lease">{t("dashboard.lease")}</option>
              <option value="Development">{t("dashboard.development")}</option>
            </Field>
            <p className={styles.form_valid_error}>
              <ErrorMessage name="listingType" />
            </p>
          </div>
          {values.listingType === "Development" && (
            <div>
              <label className={styles.form_label}>Developer Share</label>

              <div className="tw-flex tw-flex-row tw-items-center tw-space-x-2">
                <Field
                  className="form-control tw-w-32"
                  name="developerShare"
                  placeholder="Enter ratio"
                  type="number"
                ></Field>
                <div className="tw-font-bold">%</div>
              </div>
            </div>
          )}
          {values.listingType && (
            <div className="tw-flex tw-flex-row tw-space-x-2">
              <div>
                <label
                  htmlFor={listingTypeMap[values.listingType]}
                  className={styles.form_label}
                >
                  {listingTypeMap[values.listingType].charAt(0).toUpperCase() +
                    listingTypeMap[values.listingType].slice(1)}
                </label>
                <div className={styles.form_field}>
                  <Field
                    className="form-control"
                    name={listingTypeMap[values.listingType]}
                    placeholder="Enter in rupees"
                    type="number"
                  />
                  <p className={styles.form_valid_error}>
                    <ErrorMessage name={listingTypeMap[values.listingType]} />
                  </p>
                </div>
              </div>
              <div>
                <label
                  htmlFor={listingTypeMap[values.listingType]}
                  className={styles.form_label}
                >
                  {listingTypeMap[values.listingType].charAt(0).toUpperCase() +
                    listingTypeMap[values.listingType].slice(1)}{" "}
                  Unit
                </label>
                <div className={styles.form_field}>
                  <Field className="form-control" name="landUnit" as="select">
                    <option value="per Acre">per Acre</option>
                    <option value="per SqYard">per Sq.Yard</option>
                    <option value="per Gunta">per Gunta</option>
                    <option value="per Gadi">per Gadi</option>
                  </Field>
                  <p className={styles.form_valid_error}>
                    <ErrorMessage name="landUnit" />
                  </p>
                </div>
              </div>
              {values.listingType === "Lease" && (
                <div>
                  <label htmlFor="rentFlow" className={styles.form_label}>
                    Rent Paid
                  </label>
                  <div className={styles.form_field}>
                    <Field className="form-control" name="rentFlow" as="select">
                      <option value="Monthly">Monthly</option>
                      <option value="Quarterly">Quarterly</option>
                      <option value="Halverly">Halverly</option>
                      <option value="Yearly">Yearly</option>
                    </Field>
                    <p className={styles.form_valid_error}>
                      <ErrorMessage name="rentFlow" />
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
          <label htmlFor="landExtent" className={styles.form_label}>
            Land Extent
          </label>
          <div className={styles.form_field}>
            <Field
              className="form-control"
              name="landExtent"
              placeholder={`Enter in ${values.landUnit}`}
              type="number"
            />
          </div>{" "}
          <p className={styles.form_valid_error}>
            <ErrorMessage name="landExtent" />
          </p>
          <label htmlFor="totalAmount" className={styles.form_label}>
            Total Amount
          </label>
          <div className={styles.form_field}>
            <Field
              disabled
              className="form-control"
              name="totalAmount"
              type="number"
              value={
                values.landExtent *
                (values.price || values.rent || values.advance)
              }
            />
            <p className={styles.form_valid_error}>
              <ErrorMessage name="totalAmount" />
            </p>
          </div>
          <div>
            <label className={styles.form_label}>
              Choose your property location
            </label>

            <Map
              latitude={values.latitude}
              longitude={values.longitude}
              setFieldValue={setFieldValue}
            />
            <p className={styles.form_valid_error}>
              <ErrorMessage name="latitude" />
            </p>
          </div>
          <div className="d-flex flex-row">
            <div className="form-group">
              <label htmlFor="city" className={styles.form_label}>
                Area
              </label>
              <Field className="form-control" name="city" />
              <p className={styles.form_valid_error}>
                <ErrorMessage name="city" />
              </p>
            </div>
            <div className="form-group">
              <label htmlFor="district" className={styles.form_label}>
                District
              </label>
              <Field className="form-control" name="district" />
              <p className={styles.form_valid_error}>
                <ErrorMessage name="district" />
              </p>
            </div>
          </div>
          <div className="d-flex flex-row">
            <div className="form-group">
              <label htmlFor="state" className={styles.form_label}>
                State
              </label>
              <Field className="form-control" name="state" />
              <p className={styles.form_valid_error}>
                <ErrorMessage name="state" />
              </p>
            </div>
            <div className="form-group">
              <label htmlFor="country" className={styles.form_label}>
                Country
              </label>
              <Field className="form-control" name="country" />
              <p className={styles.form_valid_error}>
                <ErrorMessage name="country" />
              </p>
            </div>
          </div>
          <div className="form-group">
            <label
              for="exampleFormControlTextarea1"
              className={styles.form_label}
            >
              Address
            </label>
            <Field
              as="textarea"
              className="form-control"
              name="address"
              id="exampleFormControlTextarea1"
              rows="2"
            />
            <p className={styles.form_valid_error}>
              <ErrorMessage name="address" />
            </p>
          </div>
          <button
            className="btn btn-primary mt-2"
            disabled={isSubmitting}
            type="submit"
          >
            {isSubmitting ? (
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              />
            ) : (
              t("auth.submit")
            )}
          </button>
        </Form>
      )}
    </Formik>
  );
};

const OptionalDetails = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { landSubType, listingType } = props;
  const [percentage, setPercentage] = useState(0);
  const { t, initialValues, setPropertyDetails } = props;
  console.log(listingType, landSubType);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(
          updateListedProperty(values, setSubmitting, setPercentage, history)
        );
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form className={styles.form_container}>
          <div>
            <label htmlFor="postedBy" className={styles.form_label}>
              Posted By
            </label>
            <div className={styles.form_field}>
              <Field name="postedBy" as="select" className="form-select ">
                <option value="">Select Role</option>
                <option value="Agent">Agent</option>
                <option value="Owner">Owner</option>
              </Field>
              <p className={styles.form_valid_error}>
                <ErrorMessage name="postedBy" />
              </p>
            </div>
            <div>
              <label className={styles.form_label}>
                Select all images which you want to upload
              </label>
              <input
                className="form-control"
                type="file"
                accept="image/*"
                onChange={(event) => {
                  setFieldValue("landImages", event.currentTarget.files);
                }}
                multiple
              />
              <p className={styles.form_valid_error}>
                <ErrorMessage name="landImages" />
              </p>
            </div>
            <div>
              <label className={styles.form_label}>
                Add a video of the property
              </label>
              <input
                className="form-control"
                type="file"
                accept="video/*"
                onChange={(event) => {
                  setFieldValue("landVideo", event.currentTarget.files[0]);
                }}
              />
              <p className={styles.form_valid_error}>
                <ErrorMessage name="landVideo" />
              </p>
            </div>
            <label className={styles.form_label}>Property Highlights</label>
            <div className={styles.form_field}>
              <Field
                name="nearestThings"
                placeholder="Comma separated highlights"
                className="form-control"
              />
            </div>
            <label className={styles.form_label}>Land SubType</label>
            <div className={styles.form_field}>
              {landSubType === "Agriculture" && (
                <>
                  <Field name="landSubType" as="select" className="form-select">
                    <option value="">Select Subtype</option>
                    <option value="Agriculture Cultivated">
                      Agriculture Cultivated
                    </option>
                    <option value="Agriculture Non Cultivated">
                      Agriculture Non Cultivated
                    </option>
                    <option value="Fruit Farms">Fruit Farms</option>
                  </Field>
                  <p className={styles.form_valid_error}>
                    <ErrorMessage name="contactNo" />
                  </p>
                </>
              )}
              {landSubType === "Residential" && (
                <>
                  <Field name="landSubType" as="select" className="form-select">
                    <option value="">Select Subtype</option>
                    <option value="Open Land">Open Land</option>
                    <option value="Land With House">Land With House</option>
                  </Field>
                </>
              )}
              {landSubType === "Commercial" && (
                <>
                  <Field name="landSubType" as="select" className="form-select">
                    <option value="">Select Subtype</option>
                    <option value="Mall">Land Suitable for Mall</option>
                    <option value="Office">Land Suitable for Office</option>
                    <option value="SEZ">Land Suitable for SEZ</option>
                    <option value="Industry">Land Suitable for Industry</option>
                  </Field>
                </>
              )}

              <p className={styles.form_valid_error}>
                <ErrorMessage name="landSubType" />
              </p>
            </div>
            {landSubType === "Agriculture" && (
              <>
                <label className={styles.form_label}>
                  Doing Irrigation Currently
                </label>
                <div className={styles.form_field}>
                  <Field
                    name="doingIrrigationCurrently"
                    as="select"
                    className="form-select"
                  >
                    <option value="">Select Irrigation Status</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </Field>
                  <p className={styles.form_valid_error}>
                    <ErrorMessage name="irrigationStatus" />
                  </p>
                </div>
                <label className={styles.form_label}>Soil Type</label>
                <div className={styles.form_field}>
                  <Field name="soilType" as="select" className="form-select">
                    <option value="">Select Soil Type</option>
                    <option value="Red">Red</option>
                    <option value="Black">Black</option>
                  </Field>

                  <p className={styles.form_valid_error}>
                    <ErrorMessage name="soilType" />
                  </p>
                </div>
                <label htmlFor="contactNo" className={styles.form_label}>
                  Water Availability
                </label>
                <div className={styles.form_field}>
                  <Field
                    name="waterAvailability"
                    as="select"
                    className="form-select"
                  >
                    <option value="">Select Water Availability</option>
                    <option value="Not Available">Not Available</option>
                    <option value="Less Water">Less Water</option>
                    <option value="Plenty Water">Plenty Of Water</option>
                  </Field>
                  <p className={styles.form_valid_error}>
                    <ErrorMessage name="waterAvailability" />
                  </p>
                </div>
                <label htmlFor="contactNo" className={styles.form_label}>
                  Number Of Bores
                </label>
                <div className={styles.form_field}>
                  <Field
                    className="form-control"
                    name="numberOfBores"
                    placeholder="Enter Number Of Bores"
                    type="number"
                  ></Field>
                  <p className={styles.form_valid_error}>
                    <ErrorMessage name="numberOfBores" />
                  </p>
                </div>
              </>
            )}
            <label htmlFor="landUnit" className={styles.form_label}>
              Duration For Full Payment
            </label>
            <div className={styles.form_field}>
              <Field
                className="form-control"
                name="durationForFullPayment"
                placeholder="Enter Duration"
                type="number"
              ></Field>
              <Field
                name="durationPaymentUnit"
                as="select"
                className="form-select"
              >
                <option value="Months">Months</option>
                <option value="Years">Years</option>
              </Field>
              <p className={styles.form_valid_error}>
                <ErrorMessage name="durationForFullPayment" />
              </p>
            </div>{" "}
            <label htmlFor="landUnit" className={styles.form_label}>
              Duration For Development
            </label>
            <div className={styles.form_field}>
              <Field
                className="form-control"
                name="durationForDevelopment"
                placeholder="Enter Duration"
                type="number"
              ></Field>
              <Field
                name="durationDevelopmentUnit"
                as="select"
                className="form-select"
              >
                <option value="Months">Months</option>
                <option value="Years">Years</option>
              </Field>
              <p className={styles.form_valid_error}>
                <ErrorMessage name="durationForFullPayment" />
              </p>
            </div>
            <label htmlFor="landUnit" className={styles.form_label}>
              Legal Documents Available ?
            </label>
            <div className={styles.form_field}>
              <Field name="isLegalClear" as="select" className="form-select">
                <option value="">Select Legal Documents Availability</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Field>
              <p className={styles.form_valid_error}>
                <ErrorMessage name="isLegalClear" />
              </p>
            </div>
            <label className={styles.form_label}>Facing</label>
            <div className={styles.form_field}>
              <Field name="facing" as="select" className="form-select">
                <option value="">Select Direction</option>
                <option value="East">East </option>
                <option value="West">West</option>
                <option value="North">North</option>
                <option value="South">South</option>
              </Field>
              <p className={styles.form_valid_error}>
                <ErrorMessage name="facing" />
              </p>
            </div>
            <label className={styles.form_label}>Zone</label>
            <div className={styles.form_field}>
              <Field name="zone" as="select" className="form-select">
                <option value="">Select Zone</option>
                <option value="R1">R1</option>
                <option value="R2">R2</option>
              </Field>
              <p className={styles.form_valid_error}>
                <ErrorMessage name="zone" />
              </p>
            </div>
            <label className={styles.form_label}>Approach Road Size</label>
            <div className={styles.form_field}>
              <Field
                name="approachRoadSize"
                type="number"
                placeholder="Enter Approach Road Size in square feet"
                className="form-control"
              ></Field>
              <Field
                name="approachRoadSizeUnit"
                as="select"
                className="form-select"
              >
                <option value="Feet">Feet</option>
                <option value="Meters">Meters</option>
              </Field>

              <p className={styles.form_valid_error}>
                <ErrorMessage name="approachRoadSize" />
              </p>
            </div>
            <label className={styles.form_label}>Road Facing Width</label>
            <div className={styles.form_field}>
              <Field
                name="roadFacingWidth"
                type="number"
                className="form-control"
                placeholder="Enter Road Facing Width"
              ></Field>
              <Field
                name="roadFacingWidthUnit"
                as="select"
                className="form-select"
              >
                <option value="Feet">Feet</option>
                <option value="Meters">Meters</option>
              </Field>
              <p className={styles.form_valid_error}>
                <ErrorMessage name="roadFacingWidth" />
              </p>
            </div>
            {isSubmitting ? (
              <button className="btn btn-primary mt-2" disabled type="submit">
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                {percentage > 0 && percentage !== 100
                  ? `Uploading ${percentage}%`
                  : "Processing"}
              </button>
            ) : (
              <button className="btn btn-primary mt-2" type="submit">
                {t("auth.submit")}
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default function Listing() {
  const { t } = useTranslation();
  const [propertyDetails, setPropertyDetails] = useState(0);
  const [landSubType, setLandSubtype] = useState("");
  const [listingType, setListingType] = useState("");

  const propertyDetailsIV = {
    landType: "",
    listingType: "",
    price: "",
    landUnit: "per Acre",
    rent: "",
    rentFlow: "Monthly",
    advance: "",
    landExtent: "",
    developerShare: "",
    latitude: "0",
    longitude: "0",
    city: "",
    district: "",
    state: "",
    country: "",
    address: "",
  };
  const propertyDetailsVS = Yup.object({
    landExtent: Yup.number().required(t("auth.required")),
    state: Yup.string().required(t("auth.required")),
    city: Yup.string().required(t("auth.required")),
    district: Yup.string().required(t("auth.required")),
    address: Yup.string().required(t("auth.required")),
    latitude: Yup.number().required(t("auth.required")),
  });

  const propertyDetailsV = (values) => {
    const errors = {};
    if (
      !values[listingTypeMap[values.listingType]] ||
      values[listingTypeMap[values.listingType]] === ""
    )
      errors[listingTypeMap[values.listingType]] = t("auth.required");
    return errors;
  };

  const optionalDetailsIV = {
    landImages: "",
    landVideo: "",
    postedBy: "",
    landSubType: "",
    doingIrrigationCurrently: "",
    contactNumber: "",
    soilType: "",
    numberOfBores: "",
    waterAvailability: "",
    durationForFullPayment: "",
    durationPaymentUnit: "Months",
    durationForDevelopment: "",
    durationDevelopmentUnit: "Months",
    isLegalClear: "",
    facing: "",
    zone: "",
    roadFacingWidth: "",
    roadFacingWidthUnit: "Feet",
    approachRoadSize: "",
    approachRoadSizeUnit: "Feet",
    nearestThings: "",
  };

  return (
    <div>
      <div className={styles.form_card}>
        <Steps
          size="default"
          current={propertyDetails}
          className={styles.form_status}
          responsive
        >
          <Step title={t("dashboard.details")} />
          <Step title={t("dashboard.optional")} />
        </Steps>
        <div className="d-flex flex-row">
          {/* <button className="btn btn-info btn-sm mr-3">Back</button> */}
          {/* <h3>
            {propertyDetails === 0 && t("dashboard.details")}
            {propertyDetails === 1 && t("dashboard.optional")}
          </h3> */}
        </div>
        {propertyDetails === 0 && (
          <PropertyDetails
            t={t}
            initialValues={propertyDetailsIV}
            validationSchema={propertyDetailsVS}
            validate={propertyDetailsV}
            setPropertyDetails={setPropertyDetails}
            setLandSubtype={setLandSubtype}
            setListingType={setListingType}
          />
        )}
        {propertyDetails === 1 && (
          <OptionalDetails
            landSubType={landSubType}
            listingType={listingType}
            t={t}
            initialValues={optionalDetailsIV}
            setPropertyDetails={setPropertyDetails}
          />
        )}
      </div>
    </div>
  );
}
