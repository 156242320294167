import { Link } from "react-router-dom";
import styles from "./Dashboard.module.css";

export default function Dashboard() {
  return (
    <div className={styles.dashboard}>
      <div className={styles.dash_card}>
        <h2>
          <Link to="/dashboard/listing">Add Listing</Link>
        </h2>
      </div>
      <div className={styles.dash_card}>
        <h2>
          <Link to="/dashboard/requirements">Submit Requirements</Link>
        </h2>
      </div>
    </div>
  );
}
