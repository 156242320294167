import { notification } from "antd";
import "antd/dist/antd.css";

notification.config({
  placement: "topRight",
  top: 54,
  duration: 5,
});

export const errorHandler = (err) => {
  // TODO: Handle errors
  if (err.response) {
    notification.error({
      message: "Somethings wrong!",
    });
  } else {
    notification.error({
      message: "Can't reach server!",
    });
  }
  console.log(err.response);
};
