import React, { useState } from "react";
import Geocode from "react-geocode";

import {
  Autocomplete,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const options = {
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControlOptions: {
    position: 1,
  },
};

const libraries = ["places", "drawing"];

const API_KEY = "AIzaSyAazYFlb3DjstqiFKOsJxk90zJ6LlsejPc";

Geocode.setApiKey(API_KEY);
Geocode.setRegion("in");

const geoCoding = (setFieldValue, lat, lng) => {
  setFieldValue("latitude", lat);
  setFieldValue("longitude", lng);
  Geocode.fromLatLng(lat, lng).then(
    (response) => {
      const address = response.results[0].formatted_address;
      let city, state, country, area;
      for (let i = 0; i < response.results[0].address_components.length; i++) {
        for (
          let j = 0;
          j < response.results[0].address_components[i].types.length;
          j++
        ) {
          switch (response.results[0].address_components[i].types[j]) {
            case "locality":
              city = response.results[0].address_components[i].long_name;
              break;
            case "administrative_area_level_1":
              state = response.results[0].address_components[i].long_name;
              break;
            case "administrative_area_level_2":
              area = response.results[0].address_components[i].long_name;
              break;
            case "country":
              country = response.results[0].address_components[i].long_name;
              break;
          }
        }
      }

      setFieldValue("city", city);
      setFieldValue("district", area);
      setFieldValue("state", state);
      setFieldValue("country", country);
      setFieldValue("address", address);
    },
    (error) => {
      console.error(error);
    }
  );
};

function Map(props) {
  const { latitude, longitude, setFieldValue } = props;
  const [center, setCenter] = useState({
    lat: 17.385044,
    lng: 78.486671,
  });

  const [autocomplete, setAutocomplete] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    libraries: libraries,
    googleMapsApiKey: API_KEY,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onMarkerLoad = (marker) => {
    // console.log("Marker loaded: ", marker);
  };

  const onAutoCompleteLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onAutoCompletePlaceChanged = () => {
    if (autocomplete !== null) {
      const a = autocomplete.getPlace();
      if (a.geometry) {
        const lat = a.geometry.location.lat();
        const lng = a.geometry.location.lng();

        setCenter({
          lat,
          lng,
        });
        map.setZoom(20);

        geoCoding(setFieldValue, lat, lng);
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      mapTypeId="satellite"
      zoom={12}
      onLoad={onLoad}
      options={options}
      onUnmount={onUnmount}
      onClick={(e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        geoCoding(setFieldValue, lat, lng);
      }}
    >
      {latitude && longitude && (
        <Marker
          onLoad={onMarkerLoad}
          position={{ lat: latitude, lng: longitude }}
        />
      )}
      <Autocomplete
        style={{ position: "absolute" }}
        onLoad={onAutoCompleteLoad}
        onPlaceChanged={onAutoCompletePlaceChanged}
      >
        <input
          type="text"
          placeholder="Enter property location"
          style={{
            boxSizing: `border-box`,
            border: `1px solid transparent`,
            width: `240px`,
            height: `32px`,
            padding: `0 12px`,
            borderRadius: `3px`,
            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            fontSize: `14px`,
            outline: `none`,
            textOverflow: `ellipses`,
            position: "absolute",
            left: "50%",
            marginLeft: "-120px",
          }}
        />
      </Autocomplete>
    </GoogleMap>
  ) : (
    <div>
      <div className="spinner-border m-5" role="status"></div>
    </div>
  );
}

export default React.memo(Map);
