import Loader from "components/global/loader/Loader";
import CarouselLand from "components/carousel/CarouselLand";
import axios from "api/interceptor";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import styles from "./Land.module.css";
import { errorHandler } from "api/errorHandler";
import { Formik, Field, Form, ErrorMessage } from "formik";
import LandDetailsMap from "components/maps/LandDetailsMap";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import { Carousel } from "antd";
import NoImage from "images/noimage.jpg";

export default function Land() {
  const { t } = useTranslation();
  const { name } = useParams();
  const [land, setLand] = useState(null);
  const [landLoader, setLandLoader] = useState(true);
  const SideCard = ({ data }) => {
    const landmarks = data?.split(",");
    return (
      <>
        <div className={styles.SideCard}>
          <h1>Why Choose this ?</h1>
          <ul>
            {data && (
              <>
                {landmarks.map((item) => {
                  if (item != "") {
                    return <li>{item}</li>;
                  }
                })}
              </>
            )}
            {!data && (
              <>
                <li>Genuine Properties</li>
              </>
            )}
          </ul>
          <br />
          <h2>Get a Call back</h2>
          <Formik
            initialValues={{ name: "", phone: "" }}
            validationSchema={Yup.object({
              name: Yup.string().required(t("auth.required")),
              phone: Yup.string()
                .required(t("auth.required"))
                .min(10, "Must be 10 characters")
                .max(10, "Must be 10 characters"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              axios({
                method: "post",
                url: "/callbacks",
                data: values,
              })
                .then((res) => {
                  notification.success({
                    message: "Will call back ASAP!!!",
                  });
                  setSubmitting(false);
                })
                .catch((err) => {
                  errorHandler(err);
                  setSubmitting(false);
                });
            }}
          >
            <Form>
              <Field
                className="form-control"
                type="text"
                name="name"
                placeholder="Name"
                style={{
                  width: "80%",
                  marginBotton: "auto",
                  marginTop: "10px",
                }}
              />
              <p style={{ color: "red" }}>
                <ErrorMessage name="name" />
              </p>

              <Field
                className="form-control"
                type="number"
                name="phone"
                placeholder="Phone Number"
                style={{
                  width: "80%",
                }}
              />
              <p style={{ color: "red" }}>
                <ErrorMessage name="phone" />
              </p>
              <button
                className="btn btn-primary "
                type="submit"
                style={{
                  marginTop: "10px",
                  padding: "5px 25px",
                  color: "white",
                  backgroundColor: "rgb(15, 15, 116)",
                  border: "1px solid black",
                }}
              >
                Submit
              </button>
            </Form>
          </Formik>
        </div>
      </>
    );
  };
  const DetailsBox = ({ Text, Value }) => (
    <>
      {Value && (
        <>
          <div className={styles.details_box}>
            <h2>{Text}</h2>
            <h3>{Value}</h3>
          </div>
        </>
      )}
    </>
  );
  const PropertyDetails = ({ data }) => {
    const {
      landType,
      listingType,
      price,
      rent,
      advance,
      landUnit,
      landExtent,
      developerShare,
    } = data;
    const convertNumber = function (num) {
      let length = num.toString().length;
      if (length > 4 && length < 6) {
        // num = num / 1000;
        // num = ` ${num.toFixed(1).toString()} K`;
      } else if (length > 5 && length < 8) {
        num = num / 100000;
        num = ` ${num.toString()} Lac`;
      } else if (length > 7 && length < 10) {
        num = num / 10000000;
        num = ` ${num.toString()} Cr`;
      } else if (length >= 10) {
        num = num / 1000000000;
        num = ` ${num.toString()} Arab`;
      }
      return num;
    };
    const listingLabel = price
      ? "Price"
      : rent
      ? "Lease"
      : advance
      ? "Advance"
      : null;
    return (
      <>
        <div className={styles.PropertyDetails}>
          <h1 className={styles.Property_heading}>Property Details</h1>
          <DetailsBox Text="Land Type" Value={landType} />
          <DetailsBox Text="Listing Type" Value={listingType} />
          {developerShare && (
            <DetailsBox Text="Developer Ratio" Value={developerShare + " %"} />
          )}
          {listingType !== "Development" && (
            <DetailsBox
              Text={`${listingLabel} Per Unit`}
              Value={`Rs ${convertNumber(price || rent || advance)} / ${
                landUnit.split(" ")[1]
              }`}
            />
          )}
          <DetailsBox
            Text="Land Extent"
            Value={`${landExtent} ${landUnit.split(" ")[1]}s`}
          />
          <DetailsBox
            Text={`Total ${listingLabel}`}
            Value={`Rs ${convertNumber(
              (price || rent || advance) * landExtent
            )}`}
          />
        </div>
      </>
    );
  };

  const AdditionalDetails = ({ data }) => {
    const {
      listingType,
      price,
      rent,
      advance,
      landUnit,
      landExtent,
      postedBy,
      landSubType,
      doingIrrigationCurrently,
      soilType,
      waterAvailability,
      numberOfBores,
      durationForFullPayment,
      durationPaymentUnit,
      paymentTimeUnit,
      durationForDevelopment,
      durationDevelopmentUnit,
      approachRoadSizeUnit,
      isLegalClear,
      facing,
      zone,
      roadFacingWidth,
      roadFacingWidthUnit,
      approachRoadSize,
    } = data;

    const convertNumber = function (num = 100000) {
      let length = num.toString().length;
      if (length > 4 && length < 6) {
        // num = num / 1000;
        // num = ` ${num.toFixed(1).toString()} K`;
      } else if (length > 5 && length < 8) {
        num = num / 100000;
        num = ` ${num.toString()} Lac`;
      } else if (length > 7 && length < 10) {
        num = num / 10000000;
        num = ` ${num.toString()} Cr`;
      } else if (length >= 10) {
        num = num / 1000000000;
        num = ` ${num.toString()} Arab`;
      }
      return num;
    };

    const listingLabel = price
      ? "Price"
      : rent
      ? "Lease"
      : advance
      ? "Advance"
      : null;

    return (
      <>
        {(postedBy ||
          landSubType ||
          doingIrrigationCurrently ||
          soilType ||
          waterAvailability ||
          numberOfBores ||
          durationForFullPayment ||
          durationForDevelopment ||
          isLegalClear ||
          facing ||
          zone ||
          approachRoadSize ||
          roadFacingWidth) && (
          <div
            className={`${styles.PropertyDetails} && ${styles.AdditionalDetails}`}
          >
            <h1 className={styles.Property_heading}>Additional Details</h1>

            <DetailsBox Text="Posted By" Value={postedBy} />
            <DetailsBox Text="Land Sub Type" Value={landSubType} />
            <DetailsBox
              Text="Currently Irrigated"
              Value={doingIrrigationCurrently}
            />
            <DetailsBox Text="Soil Type" Value={soilType} />
            <DetailsBox Text="Water Availabiltity" Value={waterAvailability} />
            <DetailsBox Text="Number Of Bores" Value={numberOfBores} />

            {durationForFullPayment && (
              <DetailsBox
                Text="Duration For Full Payment"
                Value={durationForFullPayment + " " + durationPaymentUnit}
              />
            )}
            <DetailsBox Text="Payment Time Unit" Value={paymentTimeUnit} />
            {durationForDevelopment && (
              <DetailsBox
                Text="Duration For Development"
                Value={durationForDevelopment + " " + durationDevelopmentUnit}
              />
            )}
            <DetailsBox Text="Legal Documents Available" Value={isLegalClear} />
            <DetailsBox Text="Facing Direction" Value={facing} />
            <DetailsBox Text="Property Zone" Value={zone} />
            {approachRoadSize && (
              <DetailsBox
                Text="Approach Road Size"
                Value={approachRoadSize + " " + approachRoadSizeUnit}
              />
            )}
            {roadFacingWidth && (
              <DetailsBox
                Text="Road Facing Width"
                Value={roadFacingWidth + " " + roadFacingWidthUnit}
              />
            )}
            {listingType === "Development" && (
              <DetailsBox
                Text={`${listingLabel} Per Unit`}
                Value={`Rs ${convertNumber(price || rent || advance)} / ${
                  landUnit.split(" ")[1]
                }`}
              />
            )}

            {listingType === "Development" && (
              <DetailsBox
                Text={`Total ${listingLabel}`}
                Value={`Rs ${convertNumber(
                  (price || rent || advance) * landExtent
                )}`}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const Location = () => (
    <div className={styles.location}>
      <h2 className={styles.Property_heading}>Location</h2>
      <div className="tw-pt-4">
        <LandDetailsMap
          location={{ latitude: land.latitude, longitude: land.longitude }}
        />
      </div>
      <div className="tw-pt-4">
        <div className={styles.details_box}>
          <h2>Address</h2>
          <h3>{land.address}</h3>
        </div>
      </div>
    </div>
  );
  const Video = ({ link }) => (
    <>
      <video
        width="320"
        height="240"
        autoPlay
        muted
        controls="true"
        className={styles.propety_video}
      >
        <source src={link} type="video/mp4" />
      </video>
    </>
  );

  const [images, setImages] = useState([]);

  const ImageCarousel = () => {
    const contentStyle = {
      height: "160px",
      color: "#fff",
      lineHeight: "160px",
      textAlign: "center",
      background: "#364d79",
    };
    return (
      <Carousel autoplay>
        <div>
          <h3 style={contentStyle}>1</h3>
        </div>
        <div>
          <h3 style={contentStyle}>2</h3>
        </div>
        <div>
          <h3 style={contentStyle}>3</h3>
        </div>
        <div>
          <h3 style={contentStyle}>4</h3>
        </div>
      </Carousel>
    );
  };

  const ImageCarousel2 = () => {
    return (
      <div>
        {images.length > 0 ? (
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to={"0"}
                className="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to={"1"}
                className="active"
              ></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={images[0]} />
              </div>
              <div className="carousel-item active">
                <img src={images[1]} />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        ) : (
          <div>No Images Found</div>
        )}
      </div>
    );
  };

  useEffect(() => {
    axios({
      method: "get",
      url: `/lands?landName=${name}`,
    })
      .then((res) => {
        setLandLoader(false);
        if (res.data.length > 0) {
          setLand(res.data[0]);
          setImages(res.data[0].landImages.map((item) => item.url));
        }
      })
      .catch((err) => {
        setLandLoader(false);
        errorHandler(err);
        console.log(err.response);
      });
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      {landLoader ? (
        <Loader />
      ) : (
        <div>
          {land ? (
            <>
              <div className={styles.top_container}>
                <div className={styles.img_container}>
                  {images[0] && (
                    <img
                      src={images[0]}
                      alt="image1"
                      className={styles.image1}
                    />
                  )}
                  {/* <CarouselLand /> */}

                  {images[0] || images[2] ? null : (
                    <img
                      src={NoImage}
                      alt="No image is available for the property"
                      className={styles.image1}
                    />
                  )}
                </div>

                <SideCard data={land?.nearestThings} />
              </div>
              <div className={styles.mid_container}>
                <div className={styles.details_container}>
                  <PropertyDetails data={land} />
                  <AdditionalDetails data={land} />
                  <Location />
                </div>
              </div>

              {land.landVideo?.url && <Video link={land.landVideo?.url} />}
            </>
          ) : (
            <div className={styles.form_card}>
              <h4>No land found</h4>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
