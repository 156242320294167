import { useState } from "react";
import { Formik, Field, Form } from "formik";
import axios from "api/interceptor";
import { Collapse } from "antd";
import { errorHandler } from "api/errorHandler";
import qs from "qs";
import style from "./LandsFilter.module.css";

const { Panel } = Collapse;

const FilterForm = (props) => {
  const { landType, setLands, setClicked } = props;

  return (
    <Formik
      initialValues={{
        landType: [landType],
        listingType: [],
      }}
      onSubmit={(values) => {
        let params = [];

        Object.keys(values).forEach((key) => {
          if (values[key].length > 0) {
            params.push({ [key + "_in"]: values[key] });
          }
        });

        const query = qs.stringify({
          _where: params,
          _sort: "createdAt:DESC",
        });

        axios({
          method: "get",
          url: `/lands?${query}`,
        })
          .then((res) => {
            setLands(res.data);
          })
          .catch((err) => {
            errorHandler(err);
          });
      }}
    >
      {({}) => (
        <Form>
          <Collapse defaultActiveKey={["1", "2"]}>
            <Panel header="Land Type" key="1">
              <div
                role="group"
                aria-labelledby="land-type-checkbox-group"
                className="tw-flex tw-flex-col tw-space-y-2"
              >
                {["Agriculture", "Residential", "Commercial"].map((f, i) => (
                  <label className="form-check form-check-inline" key={i}>
                    <Field
                      className="form-check-input"
                      type="checkbox"
                      name="landType"
                      value={f}
                    />
                    {f}
                  </label>
                ))}
              </div>
            </Panel>
            <Panel header="Listing Type" key="2">
              <div
                role="group"
                aria-labelledby="listing-type-checkbox-group"
                className="tw-flex tw-flex-col tw-space-y-2"
              >
                {["Sale", "Lease", "Development"].map((f, i) => (
                  <label className="form-check form-check-inline" key={i}>
                    <Field
                      className="form-check-input"
                      type="checkbox"
                      name="listingType"
                      value={f}
                    />
                    {f}
                  </label>
                ))}
              </div>
            </Panel>
          </Collapse>

          <button
            className="tw-block tw-text-center tw-my-5  tw-text-white tw-bg-gray-800 tw-p-3 tw-duration-300 tw-rounded-sm hover:tw-bg-black tw-w-full"
            type="submit"
            onClick={() => setClicked(false)}
          >
            Search
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default function LandsFilter(props) {
  const { setLands, landType } = props;
  const [clicked, setClicked] = useState(false);
  return (
    <>
      <button className={style.filter_button} onClick={() => setClicked(true)}>
        Filters
      </button>

      <div
        className={
          clicked
            ? `${style.filterContainer} ${style.display}`
            : `${style.filterContainer}`
        }
      >
        <div className="tw-text-left tw-text-2xl tw-pb-2">Filter</div>
        <div>
          <FilterForm
            landType={landType}
            setLands={setLands}
            setClicked={setClicked}
          />
        </div>
      </div>
    </>
  );
}
