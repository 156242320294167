import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const myRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== "/") {
      myRef.current.scrollIntoView();
    }
  }, [pathname]);

  return <div ref={myRef}></div>;
}
