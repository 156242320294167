// import { notification } from "antd";
import axios from "axios";

let api = axios.create({
  baseURL: "https://propking.in:440/", // Production server
  // baseURL: "https://propking.in:439/", // staging server
  // baseURL: "http://localhost:1337/",
  timeout: 60000,
});

export default api;
