import React from "react";
import { Link } from "react-router-dom";

export default function NavbarBoot() {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-light"
      style={{ backgroundColor: "ghostwhite" }}
    >
      <div className="container-fluid">
        <Link className="navbar-brand tw-font-bold" to="/">
          Lands360
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse tw-font-bold"
          id="navbarNavAltMarkup"
        >
          <div
            className="navbar-nav tw-mx-auto tw-space-x-5 tw-text-black"
            style={{ fontSize: "1.2em", color: "black" }}
          >
            <a></a>
            <a className="nav-link" href="/lands?landType=Agriculture">
              Agricultural
            </a>
            <a
              className="nav-link"
              aria-current="page"
              href="/lands?landType=Residential"
            >
              Residential
            </a>
            <a
              className="nav-link"
              aria-current="page"
              href="/lands?landType=Commercial"
            >
              Commercial
            </a>
            <Link
              className="nav-link"
              aria-current="page"
              to="/dashboard/requirements"
            >
              Submit Requirements
            </Link>
            <Link
              className="nav-link"
              aria-current="page"
              to="/dashboard/listing"
            >
              Add Listings
            </Link>

            <Link className="tw-block sm:tw-hidden nav-link" to="/dashboard">
              Dashboard
            </Link>
          </div>
        </div>
        <Link
          class="tw-hidden sm:tw-block"
          to="/dashboard"
          style={{ fontSize: "1.2em", color: "black" }}
        >
          Dashboard
        </Link>
      </div>
    </nav>
  );
}
