import React, { useState } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const options = {
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControlOptions: {
    position: 1,
  },
};

const libraries = ["places", "drawing"];

const API_KEY = "AIzaSyAazYFlb3DjstqiFKOsJxk90zJ6LlsejPc";

function LandDetailsMap(props) {
  const { latitude, longitude } = props.location;
  const [location, setLocation] = useState({ lat: latitude, lng: longitude });
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    libraries: libraries,
    googleMapsApiKey: API_KEY,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onMarkerLoad = (marker) => {};

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat: latitude, lng: longitude }}
      zoom={12}
      mapTypeId="satellite"
      onLoad={onLoad}
      options={options}
      onUnmount={onUnmount}
    >
      {latitude && longitude && (
        <Marker onLoad={onMarkerLoad} position={location} />
      )}
    </GoogleMap>
  ) : (
    <div>
      <div className="spinner-border m-5" role="status"></div>
    </div>
  );
}

export default React.memo(LandDetailsMap);
