import axios from "api/interceptor";
import { createSlice } from "@reduxjs/toolkit";
import { errorHandler } from "api/errorHandler";
import { selectToken, selectUser } from "./userSlice";
import { notification } from "antd";

/**
 * Initial state
 */
const initialState = {
  land: null,
};

/**
 * Slice
 */
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateLand: (state, action) => {
      state.land = action.payload;
    },
  },
});

/**
 * Reducer
 */
export default dashboardSlice.reducer;

/**
 * Actions
 */
export const { updateLand } = dashboardSlice.actions;

/**
 * Selectors
 */
export const selectLand = (state) => state.dashboard.land;

/**
 * Thunks
 */
export const listProperty =
  (data, setSubmitting, setPropertyDetails) => (dispatch, getState) => {
    const token = selectToken(getState());
    const user = selectUser(getState());

    data = {
      ...data,
      user: user.id,
    };

    axios({
      method: "post",
      url: "/lands",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((res) => {
        setSubmitting && setSubmitting(false);
        setPropertyDetails && setPropertyDetails(1);
        console.log(res.data);
        notification.success({
          message: "Added property details!",
          description: "Your property will get listed after verification",
        });
        dispatch(updateLand(res.data));
      })
      .catch((err) => {
        setSubmitting && setSubmitting(false);
        errorHandler(err);
      });
  };

export const updateListedProperty =
  (data, setSubmitting, setPercentage, history) => (dispatch, getState) => {
    const token = selectToken(getState());
    const land = selectLand(getState());

    let formData = new FormData();

    if (data.landImages.length > 0) {
      for (var i = 0; i < data.landImages.length; i++) {
        formData.append(
          "files.landImages",
          data.landImages[i],
          land.landId + "_" + data.landImages[i].name
        );
      }
    }

    if (data.landVideo.length > 0) {
      formData.append(
        "files.landVideo",
        data.landVideo,
        land.landId + "_" + data.landVideo.name
      );
    }

    delete data.landImages;
    delete data.landVideo;

    Object.keys(data).forEach((key) => {
      if (!data[key]) delete data[key];
    });

    formData.append("data", JSON.stringify(data));

    axios({
      method: "put",
      url: `/lands/${land.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setPercentage(percentCompleted);
      },
    })
      .then((res) => {
        setSubmitting && setSubmitting(false);
        notification.success({
          message: "Additonal details added",
        });
        dispatch(updateLand(res.data));
        history.push("/dashboard");
      })
      .catch((err) => {
        console.log(err);
        setSubmitting && setSubmitting(false);
        errorHandler(err);
      });
  };

export const addPropertyRequirements =
  (data, setSubmitting) => (dispatch, getState) => {
    const token = selectToken(getState());
    const user = selectUser(getState());

    data = { ...data, user: user.id };

    axios({
      method: "post",
      url: "/requirements",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((res) => {
        setSubmitting && setSubmitting(false);
        notification.success({
          message: "Added property requirements!",
        });
        console.log(res.data);
      })
      .catch((err) => {
        setSubmitting && setSubmitting(false);
        errorHandler(err);
      });
  };
