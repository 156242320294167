import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { signupRequest } from "app/slices/userSlice";

import styles from "styles/Auth.module.css";
import React from "react";

const SignupForm = (props) => {
  const { t, initialValues, validationSchema } = props;
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(signupRequest(values, setSubmitting));
      }}
    >
      {({ isSubmitting }) => (
        <Form className={styles.form_container}>
          <div className={styles.form_field}>
            <Field
              className={`form-control ${styles.Input}`}
              name="name"
              type="text"
              placeholder={t("auth.name")}
            />
            <p className={styles.form_valid_error}>
              <ErrorMessage name="name" />
            </p>
          </div>

          <div className={styles.form_field}>
            <Field
              className={`form-control ${styles.Input}`}
              name="username"
              type="text"
              placeholder={t("auth.phone")}
            />
            <p className={styles.form_valid_error}>
              <ErrorMessage name="username" />
            </p>
          </div>

          <div className={styles.form_field}>
            <Field
              className={`form-control ${styles.Input}`}
              name="email"
              type="email"
              placeholder={t("auth.email")}
            />
            <p className={styles.form_valid_error}>
              <ErrorMessage name="email" />
            </p>
          </div>

          <div className={styles.form_field}>
            <Field
              name="persona"
              as="select"
              className={`form-select ${styles.Input}`}
            >
              <option disabled> {t("auth.persona")}</option>
              <option value="Builder">{t("auth.builder")}</option>
              <option value="Agent">{t("auth.agent")}</option>
              <option value="Customer">{t("auth.customer")}</option>
            </Field>
            <p className={styles.form_valid_error}>
              <ErrorMessage name="persona" />
            </p>
          </div>

          <div className={styles.form_field}>
            <Field
              className={`form-control ${styles.Input}`}
              name="password"
              type="password"
              placeholder={t("auth.password")}
            />
            <p className={styles.form_valid_error}>
              <ErrorMessage name="password" />
            </p>
          </div>
          <p
            style={{
              color: "gray",
              fontSize: "0.5em",
              textAlign: "center",
              marginTop: "2px",
            }}
          >
            By signing up you agree to our Terms of Service and Privacy Policy
          </p>
          <button
            className={` btn btn-primary ${styles.btn} `}
            disabled={isSubmitting}
            type="submit"
          >
            {isSubmitting ? (
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              />
            ) : (
              t("auth.submit")
            )}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default function Signup() {
  const { t } = useTranslation();

  const initialValues = {
    name: "",
    username: "",
    email: "",
    persona: "Customer",
    password: "",
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(24, "Must be less than 24 characters")
      .required(t("auth.required")),
    username: Yup.string()
      .matches(phoneRegExp, "Invalid phone number")
      .min(10, "Must be 10 characters")
      .max(10, "Must be 10 characters")
      .required(t("auth.required")),
    email: Yup.string()
      .email("Invalid email address")
      .required(t("auth.required")),
    persona: Yup.string().required(t("auth.required")),
    password: Yup.string()
      .min(6, "Must be atleast 6 characters")
      .max(20, "Must be less than 20 characters")
      .required(t("auth.required")),
  });

  return (
    <div className={styles.bg_grey}>
      <div className={styles.form_card}>
        <h2 className={styles.heading}>Signup For Lands360</h2>
        <SignupForm
          t={t}
          initialValues={initialValues}
          validationSchema={validationSchema}
        />
        <Link to="/login" className={styles.link}>
          {t("auth.login")}
        </Link>
      </div>
    </div>
  );
}
