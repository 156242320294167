import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginRequest } from "app/slices/userSlice";

import styles from "styles/Auth.module.css";

const LoginForm = (props) => {
  const dispatch = useDispatch();
  const { t, initialValues, validationSchema } = props;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(loginRequest(values, setSubmitting));
      }}
    >
      {({ isSubmitting }) => (
        <Form className={styles.form_container}>
          <div className={styles.form_field}>
            <Field
              className={`form-control ${styles.Input}`}
              name="identifier"
              type="text"
              placeholder={t("auth.identifier")}
            />
            <p className={styles.form_valid_error}>
              <ErrorMessage name="identifier" />
            </p>
          </div>

          <div className={styles.form_field}>
            <Field
              className={`form-control ${styles.Input}`}
              name="password"
              type="password"
              placeholder={t("auth.password")}
            />
            <p className={styles.form_valid_error}>
              <ErrorMessage name="password" />
            </p>
          </div>

          <button
            className={` btn btn-primary ${styles.btn} `}
            disabled={isSubmitting}
            type="submit"
          >
            {isSubmitting ? (
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              />
            ) : (
              t("auth.submit")
            )}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default function Login() {
  const { t } = useTranslation();
  const initialValues = { identifier: "", password: "" };
  const validationSchema = Yup.object({
    identifier: Yup.string().required(t("auth.required")),
    password: Yup.string().required(t("auth.required")),
  });

  return (
    <div className={styles.bg_grey}>
      <div className={styles.form_card}>
        <h1 className={styles.heading}>Login to Lands360</h1>
        <LoginForm
          t={t}
          initialValues={initialValues}
          validationSchema={validationSchema}
        />
        <Link to="/signup" className={styles.link}>
          {t("auth.signup")}
        </Link>
      </div>
    </div>
  );
}
