import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "api/interceptor";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginRequest } from "app/slices/userSlice";

import styles from "styles/Auth.module.css";
import { errorHandler } from "api/errorHandler";
import { message, notification } from "antd";

const LoginForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, initialValues, validationSchema } = props;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        axios({
          method: "post",
          url: "/callbacks",
          data: values,
        })
          .then((res) => {
            history.push("/");
            notification.success({
              message: "Will call back ASAP!!!",
            });
            setSubmitting(false);
          })
          .catch((err) => {
            errorHandler(err);
            setSubmitting(false);
          });
      }}
    >
      {({ isSubmitting }) => (
        <Form className={styles.form_container}>
          <div className={styles.form_field}>
            <Field
              className={`form-control ${styles.Input}`}
              name="name"
              type="text"
              placeholder="Name"
            />
            <p className={styles.form_valid_error}>
              <ErrorMessage name="name" />
            </p>
          </div>

          <div className={styles.form_field}>
            <Field
              className={`form-control ${styles.Input}`}
              name="phone"
              type="text"
              placeholder="Phone"
            />
            <p className={styles.form_valid_error}>
              <ErrorMessage name="phone" />
            </p>
          </div>

          <button
            className={` btn btn-primary ${styles.btn} `}
            disabled={isSubmitting}
            type="submit"
          >
            {isSubmitting ? (
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              />
            ) : (
              t("auth.submit")
            )}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default function Login() {
  const { t } = useTranslation();
  const initialValues = { name: "", phone: "" };
  const validationSchema = Yup.object({
    name: Yup.string().required(t("auth.required")),
    phone: Yup.string()
      .required(t("auth.required"))
      .min(10, "Must be 10 characters")
      .max(10, "Must be 10 characters"),
  });

  return (
    <div className={styles.bg_grey}>
      <div className={styles.form_card}>
        <h1 className={styles.heading}>Get a Call back</h1>
        <LoginForm
          t={t}
          initialValues={initialValues}
          validationSchema={validationSchema}
        />
      </div>
    </div>
  );
}
