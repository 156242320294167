import { Card } from "antd";
import agri from "images/lands/agri.jpeg";
import resi from "images/lands/resi.jpg";
import comm from "images/lands/comm.jpg";
import { Link } from "react-router-dom";
import styles from "./LandCard.module.css";
import LocationOnIcon from "@material-ui/icons/LocationOn";

export default function LandCard(props) {
  const {
    price,
    rent,
    advance,
    landUnit,
    landName,
    landType,
    listingType,
    landExtent,
    landImages,
    city,
    state,
    developerShare,
  } = props.land;

  const getThumbnail = () => {
    if (landImages.length > 0) {
      const image = landImages[0];
      return image.formats.thumbnail.url;
    } else if (landType === "Agriculture") {
      return agri;
    } else if (landType === "Residential") {
      return resi;
    } else if (landType === "Commercial") {
      return comm;
    }
  };
  const convertNumber = function (num) {
    let length = num.toString().length;
    if (length > 4 && length < 6) {
      // num = num / 1000;
      // num = ` ${num.toFixed(1).toString()} K`;
    } else if (length > 5 && length < 8) {
      num = num / 100000;
      num = ` ${num.toString().slice(0, 5)} Lac`;
    } else if (length > 7 && length < 10) {
      num = num / 10000000;
      num = ` ${num.toString().slice(0, 5)} Cr`;
    } else if (length >= 10) {
      num = num / 1000000000;
      num = ` ${num.toString()} Arab`;
    }
    return num;
  };
  const image = getThumbnail();
  return (
    <Card className={styles.Card}>
      <Link to={`/lands/${landName}`} className="hover:tw-text-black">
        <img className={styles.imageland} src={image} alt="land" />
        <div className={styles.Location}>
          <LocationOnIcon /> {city}, {state}
        </div>
        <div className={styles.details}>
          {listingType === "Development" ? (
            <p className={styles.cost}>{developerShare}% Developer Share</p>
          ) : (
            <p className={styles.cost}>
              Rs {convertNumber(price || rent || advance)} /{" "}
              {landUnit.split(" ")[1]}
            </p>
          )}
          <p className={styles.total_cost}>
            {landExtent} {landUnit.split(" ")[1]}s | Rs{" "}
            {convertNumber((price || rent || advance) * landExtent)}
          </p>
          <div className={styles.land_type}>
            {landType}
            <p className={styles.listingType}> {listingType}</p>
          </div>
        </div>

        <button className={styles.btn}>Explore</button>
      </Link>
    </Card>
  );
}
