import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "components/counter/counterSlice";
import userReducer from "app/slices/userSlice";
import dashboardReducer from "app/slices/dashboardSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    dashboard: dashboardReducer,
  },
});
