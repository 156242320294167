import { useEffect } from "react";
import Router from "Router";
import "./App.css";
import "./index.css";
import "i18n";

import { useDispatch, useSelector } from "react-redux";
import { refreshUser, selectToken, stopUserLoader } from "app/slices/userSlice";

function App() {
  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  useEffect(() => {
    if (token) {
      dispatch(refreshUser());
    } else {
      dispatch(stopUserLoader());
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <Router />
    </div>
  );
}

export default App;
