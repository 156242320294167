import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import styles from "./Requirements.module.css";
import { addPropertyRequirements } from "app/slices/dashboardSlice";

const RequirementsForm = (props) => {
  const dispatch = useDispatch();
  const { t, initialValues, validationSchema } = props;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        dispatch(addPropertyRequirements(values, setSubmitting));
      }}
    >
      {({ isSubmitting }) => (
        <Form className={styles.form_container}>
          <div className={styles.form_field}>
            <label htmlFor="purpose" className={styles.form_label}>
              {t("dashboard.purpose")}
            </label>
            <Field name="purpose" as="select" className="form-select">
              <option value="Buy">{t("dashboard.buy")}</option>
              <option value="Lease">{t("dashboard.lease")}</option>
              <option value="Development">{t("dashboard.development")}</option>
            </Field>
            <p className={styles.form_valid_error}>
              <ErrorMessage name="purpose" />
            </p>
          </div>

          <div className={styles.form_field}>
            <label htmlFor="landType" className={styles.form_label}>
              {t("dashboard.landType")}
            </label>
            <Field name="landType" as="select" className="form-select">
              <option value="Agriculture">{t("dashboard.agriculture")}</option>
              <option value="Residential">{t("dashboard.residential")}</option>
              <option value="Commercial">{t("dashboard.commercial")}</option>
            </Field>
            <p className={styles.form_valid_error}>
              <ErrorMessage name="landType" />
            </p>
          </div>

          <div className={styles.form_field}>
            <label htmlFor="budget" className={styles.form_label}>
              Budget
            </label>
            <Field
              className="form-control"
              name="budget"
              placeholder="Enter in rupees"
              type="number"
            />
            <p className={styles.form_valid_error}>
              <ErrorMessage name="budget" />
            </p>
          </div>

          <div className={styles.form_field}>
            <label htmlFor="landExtent" className={styles.form_label}>
              {t("dashboard.landExtent")}
            </label>
            <div
              name="landExtent"
              className="d-flex flex-row justify-space-between"
            >
              <div>
                <Field
                  className="form-control"
                  name="landExtentStart"
                  placeholder="Min:"
                  type="number"
                />
                <p className={styles.form_valid_error}>
                  <ErrorMessage name="landExtentStart" />
                </p>
              </div>
              <div>
                <Field
                  className="form-control"
                  name="landExtentEnd"
                  placeholder="Max:"
                  type="number"
                />
                <p className={styles.form_valid_error}>
                  <ErrorMessage name="landExtentEnd" />
                </p>
              </div>
            </div>
          </div>

          <div className={styles.form_field}>
            <label htmlFor="landUnit" className={styles.form_label}>
              Land Unit
            </label>
            <Field name="landUnit" as="select" className="form-select">
              <option value="Acres">Acres</option>
              <option value="SqYards">Sq.Yards</option>
              <option value="Guntas">Guntas</option>
              <option value="Gadi">Gadi</option>
            </Field>
            <p className={styles.form_valid_error}>
              <ErrorMessage name="landType" />
            </p>
          </div>

          <button
            className="btn btn-primary mt-2"
            disabled={isSubmitting}
            type="submit"
          >
            {isSubmitting ? (
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              />
            ) : (
              t("auth.submit")
            )}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default function Requirements() {
  const { t } = useTranslation();
  const initialValues = {
    purpose: "Buy",
    landType: "Residential",
    budget: "",
    landExtentStart: "",
    landExtentEnd: "",
    landUnit: "Acres",
  };
  const validationSchema = Yup.object({
    purpose: Yup.string().required(t("auth.required")),
    landType: Yup.string().required(t("auth.required")),
    budget: Yup.number("Please enter a number").required(t("auth.required")),
    landExtentStart: Yup.number().required(t("auth.required")),
    landExtentEnd: Yup.number().required(t("auth.required")),
    landUnit: Yup.string().required(t("auth.required")),
  });

  return (
    <div className={styles.form_card}>
      <h3>{t("dashboard.requirements")}</h3>
      <RequirementsForm
        t={t}
        initialValues={initialValues}
        validationSchema={validationSchema}
      />
    </div>
  );
}
